<script>

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDialog from "@components/general/AkDialog";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import dateFormatter from "@mixins/dateFormatter";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCriseService from "@services/bilanCriseService";
import bilanCrisePeriodService from "@services/bilanCrisePeriodService";

export default {
  components: {AkFormList, AkDialog, AkDropdown},
  mixins: [randomRef, utilsMixin, dateFormatter, roleMixin],
  metaInfo() {
    return {
      title: "bilan_crise.list",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      criseList: [],
      crisePeriodList: [],
      currentYear: null,
      currentCrise: {},
      currentCrisePeriod: {},
      loading: true,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();
  
    let p1 = bilanCriseService.findAllCurrent();
    p1.then(data => this.criseList = data);
  
    let p2 = bilanCrisePeriodService.findAllCurrent();
    p2.then(data => this.crisePeriodList = data);
  
    let p3 = bilanCriseService.findCurrentYear();
    p3.then(data => this.currentYear = data);
  
    let p4 = bilanCrisePeriodService.findAllYears();
    p4.then(data => {
      this.yearList.push({value: 0, label: this.$t('current_year')});
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}))
    });
  
    Promise.all([p1, p2, p3, p4]).then(()=>{
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      let p2;
      if (year !== 0) {
        p1 = bilanCriseService.findAllByYear(year);
        p1.then(data => this.criseList = data);
      
        p2 = bilanCrisePeriodService.findAllByYear(year);
        p2.then(data => this.crisePeriodList = data);
      } else {
        p1 = bilanCriseService.findAllCurrent();
        p1.then(data => this.criseList = data);
      
        p2 = bilanCrisePeriodService.findAllCurrent();
        p2.then(data => this.crisePeriodList = data);
      }
    
      Promise.all([p1, p2]).then(()=>{
        this.loading = false;
        this.getRef().hideLoader();
        this.initData();
      });
    },
    deleteCrise() {
      this.getRef().resetMessages();
      bilanCriseService.delete(this.currentCrise).then(data => {
            this.criseList = this.criseList.filter(val => val.id !== data.id);
            this.getRef().success(this.$t("bilan_crise.deleted"));
          }
      ).catch(e => {
          this.getRef().error(e.response.data.error);
      });
    },
    deleteCrisePeriod() {
      this.getRef().resetMessages();
      bilanCrisePeriodService.delete(this.currentCrisePeriod).then(data => {
          this.crisePeriodList = this.crisePeriodList.filter(val => val.id !== data.id);
          this.getRef().success(this.$t("bilan_crise.period_deleted"));
        }
      ).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },
    openDeleteCriseDialog(data) {
      this.currentCrise = data;
      this.$refs.dialogDeleteCrise.show();
    },
    openDeleteCrisePeriodDialog(data) {
      this.currentCrisePeriod = data;
      if (this.criseList.some(crise => crise.crisePeriodId === data.id))
        this.getRef().error(this.$t('bilan_crise.period_delete_error'));
      else this.$refs.dialogDeleteCrisePeriod.show();
    }
  },
  watch: {
    year(newType) {
      this.reload(newType);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormList :ref="ref" :title="$t('bilan_crise.list')" :displayGoBack=true>
    <template v-slot:action>
      <router-link :to="{ name: 'bilanCriseSynthese' }" class="btn btn-inverse-primary mr-2">
        {{ $t('goto_table') }}
      </router-link>
      <template v-if=this.canCreateCrisis()>
        <RouterLink :to="{ name: 'bilanCriseCreate' }" class="btn btn-inverse-primary mr-2">
          <i class="fe fe fe-plus pr-1"/>{{ $t('bilan_crise.add_crise') }}
        </RouterLink>
        <RouterLink :to="{ name: 'bilanCrisePeriodCreate' }" class="btn btn-inverse-primary">
          <i class="fe fe fe-plus pr-1"/>{{ $t('bilan_crise.add_crise_period') }}
        </RouterLink>
      </template>
    </template>
    <template v-slot:list>
      <div class="row">
        <AkDropdown :label="$t('year')"
                    v-if=this.canViewCrisisArchived()
                    v-model="year"
                    :options=this.yearList
                    class-name="col-md-2"/>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <h5>Crises</h5>
              <div class="table-responsive">
                <DataTable :always-show-paginator="false" :paginator="true" :rows="10" :loading="loading"
                           :rowsPerPageOptions="[10,20,50]" :value="criseList" class="table"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort responsiveLayout="scroll" stripedRows>
                  <template>{{ $t("list_empty") }}</template>
                  <Column :header="$t('year')">
                    <template #body="slotProps">
                      <template v-if=!slotProps.data.year>{{ currentYear }} - {{ currentYear + 1 }}</template>
                      <template v-else>{{ slotProps.data.year }} - {{ slotProps.data.year + 1 }}</template>
                    </template>
                  </Column>
                  <Column field="crisePeriodLabel" :header="$t('bilan_crise.period')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.crisePeriodLabel }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.ceiLabel }}
                    </template>
                  </Column>
                  <Column field="issue" :header="$t('bilan_crise.issue')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.issue ? slotProps.data.issue.length > 30 ?  slotProps.data.issue.slice(0, 30) + "..." : slotProps.data.issue : '' }}
                    </template>
                  </Column>
                  <Column field="solution" :header="$t('bilan_crise.solution')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.solution ? slotProps.data.solution.length > 30 ?  slotProps.data.solution.slice(0, 30) + "..." : slotProps.data.solution : '' }}
                    </template>
                  </Column>
                  <Column bodyStyle="text-align: right; overflow: visible">
                    <template #body="slotProps">
                      <RouterLink :to="{name: 'bilanCriseDetails', params: {id: slotProps.data.id}}"
                                   class="btn btn-xs btn-inverse-primary">
                        <i class="fe fe-eye"/>
                      </RouterLink>
                      <RouterLink v-if="!slotProps.data.year && this.canEditCrisis()"
                                  :to="{name: 'bilanCriseUpdate', params: {id: slotProps.data.id}}"
                                   class="btn btn-xs btn-inverse-primary ml-1">
                        <i class="fe fe-edit"/>
                      </RouterLink>
                      <span v-if=this.canDeleteCrisis()
                            @click="openDeleteCriseDialog(slotProps.data)"
                            class="btn btn-xs btn-inverse-danger ml-1">
                        <i class="fe fe-trash"/>
                      </span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <h5>Périodes de crises</h5>
              <div class="table-responsive">
                <DataTable :always-show-paginator="false" :paginator="true" :rows="10" :loading="loading"
                           :rowsPerPageOptions="[10,20,50]" :value="crisePeriodList" class="table"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort responsiveLayout="scroll" stripedRows>
                  <template>{{ $t("list_empty") }}</template>
                  <Column :header="$t('year')">
                    <template #body="slotProps">
                      <template v-if=!slotProps.data.year>{{ currentYear }} - {{ currentYear + 1 }}</template>
                      <template v-else>{{ slotProps.data.year }} - {{ slotProps.data.year + 1 }}</template>
                    </template>
                  </Column>
                  <Column field="dateStart" :header="$t('bilan_crise.date_start')" :sortable="true">
                    <template #body="slotProps">
                      {{ formatDate(slotProps.data.dateStart) }}
                    </template>
                  </Column>
                  <Column field="dateEnd" :header="$t('bilan_crise.date_end')" :sortable="true">
                    <template #body="slotProps">
                      {{ formatDate(slotProps.data.dateEnd) }}
                    </template>
                  </Column>
                  <Column field="name" :header="$t('bilan_crise.name')" :sortable="true">>
                    <template #body="slotProps">
                      {{ slotProps.data.name }}
                    </template>
                  </Column>
                  <Column bodyStyle="text-align: right; overflow: visible">
                    <template #body="slotProps">
                      <RouterLink :to="{name: 'bilanCrisePeriodDetails', params: {id: slotProps.data.id}}"
                                   class="btn btn-xs btn-inverse-primary">
                        <i class="fe fe-eye"/>
                      </RouterLink>
                      <RouterLink v-if="!slotProps.data.year && this.canEditCrisis()"
                                  :to="{name: 'bilanCrisePeriodUpdate', params: {id: slotProps.data.id}}"
                                   class="btn btn-xs btn-inverse-primary ml-1">
                        <i class="fe fe-edit"/>
                      </RouterLink>
                      <span v-if=this.canDeleteCrisis()
                            @click="openDeleteCrisePeriodDialog(slotProps.data)"
                            class="btn btn-xs btn-inverse-danger ml-1">
                          <i class="fe fe-trash"/>
                        </span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog :auto-hide-on-validate="true" :cancel-label="$t('no')" :title="$t('bilan_crise.delete_crisis_dialog')"
                :validate-label="$t('yes')" @validate="this.deleteCrise()" ref="dialogDeleteCrise" width="450px">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('bilan_crise.confirm_delete_crisis') }}</span>
        </div>
      </AkDialog>
  
      <AkDialog :auto-hide-on-validate="true" :cancel-label="$t('no')" :title="$t('bilan_crise.delete_period_dialog')"
                :validate-label="$t('yes')" @validate="this.deleteCrisePeriod()" ref="dialogDeleteCrisePeriod" width="450px">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('bilan_crise.confirm_delete_period') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>